<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <!-- 개정 -->
          <c-card title="LBLREV" class="cardClassDetailForm revisionLayout" topClass="topcolor-orange" v-if="popupParam.psiProtectionEpuipmentId">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="row">
                  <div class="col-1">
                    <!-- 개정번호 -->
                    <c-text
                      :required="editable && isRevision"
                      :editable="editable && isRevision"
                      label="LBLREVNO"
                      name="revisionNum"
                      v-model="data.revisionNum">
                    </c-text>
                  </div>
                  <div class="col-2">
                    <!-- 개정일시 -->
                    <c-text
                      :editable="false"
                      label="LBLREVPERIOD"
                      name="regDtStr"
                      v-model="data.regDtStr">
                    </c-text>
                  </div>
                  <div class="col-1">
                    <!-- 개정자 -->
                    <c-text
                      :editable="false"
                      label="LBLREVUSER"
                      name="regUserName"
                      v-model="data.regUserName">
                    </c-text>
                  </div>
                  <div class="col-4">
                    <!-- 개정사유 -->
                    <c-text
                      :required="editable && isRevision"
                      :editable="editable && isRevision"
                      label="LBLREVREASON"
                      name="revisionContent"
                      v-model="data.revisionContent">
                    </c-text>
                  </div>
                </div>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <!-- 안전보호장구/비상방재물품 상세정보 -->
          <c-card title="LBL0010564" class="cardClassDetailForm">
            <template slot="card-select">
              <!-- 개정이력 -->
              <c-select
                :editable="!isRevision"
                v-if="popupParam.psiProtectionEpuipmentId"
                :comboItems="gridrev.data"
                type="custom"
                typetext="LBL0010565"
                itemText="revisionNum"
                itemValue="psiProtectionEpuipmentId"
                name="selectedpsiProtectionEpuipmentId"
                label="LBL0010565"
                v-model="selectedpsiProtectionEpuipmentId"
                @input="rowClickRev"
              ></c-select>
            </template>
            <template slot="card-button">
              <q-btn-group outline >
                <q-btn
                  icon="help"
                  color="deep-purple-6"
                  text-color="white"
                  class="custom-btn"
                  align="center"
                  round>
                  <q-tooltip anchor="bottom left" self="top left">
                    <div class="tooltipCustomTop">
                      <!-- (주) -->
                      {{ $label('LBL0010385') }}
                    </div>
                    <div class="tooltipCustom">
                    <!-- ① 개인휴대용은 제외할 수 있다. -->
                    {{ $message('MSG0010060') }}<br>
                    <!-- ② 점검주기는 화학물질관리법에서 주1회로 요구된다. -->
                    {{ $message('MSG0010061') }}
                    </div>
                  </q-tooltip>
                </q-btn>
                <!-- 개정 -->
                <c-btn
                  v-show="editable && popupParam.psiProtectionEpuipmentId && !isRevision"
                  label="LBLREV"
                  icon="restart_alt"
                  @btnClicked="SetRevision" />
                <!-- 개정취소 -->
                <c-btn
                  v-show="editable && popupParam.psiProtectionEpuipmentId && isRevision"
                  label="LBLREVCANCEL"
                  icon="restart_alt"
                  @btnClicked="CancelRevision" />
                <!-- 저장 -->
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
                <!-- 삭제 -->
                <c-btn
                  v-if="editable && popupParam.psiProtectionEpuipmentId"
                  label="LBLREMOVE"
                  icon="remove"
                  @btnClicked="deleteData" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-4">
                <!-- 구분 -->
                <c-select
                  :required="true"
                  :editable="editable"
                  codeGroupCd="SAFETY_EQUIP_TYPE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="safetyEquipTypeCd"
                  label="LBL0010557"
                  v-model="data.safetyEquipTypeCd"
                ></c-select>
              </div>
              <div class="col-4">
                <!-- 관리번호 -->
                <c-text
                  :required="true"
                  :editable="editable"
                  label="LBL0010551"
                  name="manageNo"
                  v-model="data.manageNo">
                </c-text>
              </div>
              <div class="col-4">
                <c-plant
                  :required="true"
                  :editable="editable"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd">
                </c-plant>
              </div>
              <div class="col-6">
                <!-- 지역(공정) -->
                <c-text
                  :required="true"
                  :editable="editable"
                  label="LBL0010552"
                  name="areaName"
                  v-model="data.areaName">
                </c-text>
              </div>
              <div class="col-6">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="LBL0010559"
                  name="itemName"
                  v-model="data.itemName">
                </c-text>
              </div>
              <div class="col-6">
                <!-- 설치장소 -->
                <c-text
                  :editable="editable"
                  label="LBL0010608"
                  name="location"
                  v-model="data.location">
                </c-text>
              </div>
              <div class="col-6">
                <!-- 점검주기 -->
                <c-text
                  :editable="editable"
                  label="LBL0010473"
                  name="checkCycle"
                  v-model="data.checkCycle">
                </c-text>
              </div>
              <div class="col-12">
                <!-- 비고 -->
                <c-textarea
                  :editable="editable"
                  :rows="3"
                  label="LBLREMARK"
                  name="remarks"
                  v-model="data.remarks">
                </c-textarea>
              </div>
              <div class="col-6">
                <!-- 관련공정 -->
                <c-process
                  :editable="editable"
                  label="LBL0001705"
                  multiple="single"
                  name="processCd"
                  v-model="data.processCd">
                </c-process>
              </div>
              <div class="col-6">
                <!-- 관리부서 -->
                <c-dept
                  :editable="editable"
                  label="LBL0001721"
                  name="deptCd"
                  v-model="data.deptCd">
                </c-dept>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <!-- 세부품목 -->
          <c-table
            ref="grid"
            title="LBL0010560"
            tableId="grid"
            :columns="grid.columns"
            :data="grid.data"
            selection="multiple"
            rowKey="psiProtectionEpuipmentDetailId"
            :isFullScreen="false"
            :columnSetting="false"
            :filtering="false"
            gridHeight="422px"
          >
            <!-- 버튼 영역 -->
            <template slot="table-button">
              <q-btn-group outline >
                  <!-- 추가 -->
                  <c-btn v-if="editable && popupParam.psiProtectionEpuipmentId" label="LBLADD" icon="add" @btnClicked="addrow" />
                  <!-- 제외 -->
                  <c-btn v-if="editable && popupParam.psiProtectionEpuipmentId" label="LBLREMOVE" icon="remove" @btnClicked="deleteItem" />
              </q-btn-group>
            </template>
          </c-table>
        </div>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';

export default {
  name: 'washing-safety-detail',

  props: {
    popupParam: {
      type: Object,
      default: () => ({
        psiProtectionEpuipmentId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'detailItemName',
            field: 'detailItemName',
            // 세부품목
            label: 'LBL0010560',
            align: 'center',
            type: 'text',
            sortable: false,
          },
          {
            name: 'performanceStandard',
            field: 'performanceStandard',
            // 성능 및 규격
            label: 'LBL0010561',
            align: 'center',
            type: 'text',
            sortable: false,
          },
          {
            name: 'equipAmount',
            field: 'equipAmount',
            // 비치수량
            label: 'LBL0010562',
            align: 'center',
            type: 'text',
            style: 'width:100px',
            sortable: false,
          },
        ],
        data: [],
      },
      saveCallData: '',
      selectedpsiProtectionEpuipmentId: null,
      saveUrl: transactionConfig.psi.lbe.protect.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      revlistUrl: '',
      editable: true,
      isSave: false,
      isdelete: false,
      isRevision: false,
      itemlistUrl: '',
      itemsaveUrl: '',
      itemdeleteUrl: '',
      data: {
        psiProtectionEpuipmentId: '',  // 안전보호장구_비상방재물품_일련번호
        plantCd: '',  // 사업장코드
        safetyEquipTypeCd: null,  // 안전보호장구_비상방재물품 구분
        manageNo: '',  // 관리번호
        areaName: '',  // 지역(공정)
        itemName: '',  // 품목
        location: '',  // 비치장소
        checkCycle: '',  // 점검주기
        processCd: '',
        deptCd: '',
        revisionNum: '',
        groupId: '',
        sysRevision: 1,
        revisionContent: '제정',
        regDtStr: '',
        regUserName: '',
        sopMocId: ''
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      gridrev: {
        columns: [
          {
            name: 'revisionNum',
            field: 'revisionNum',
            // 개정번호
            label: 'LBLREVNO',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
          {
            name: 'revisionContent',
            field: 'revisionContent',
            // 개정사유
            label: 'LBLREVREASON',
            align: 'left',
            sortable: true,
          },
          {
            name: 'regDtStr',
            field: 'regDtStr',
            // 개정일시
            label: 'LBLREVPERIOD',
            style: 'width: 150px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'regUserName',
            field: 'regUserName',
            // 개정자
            label: 'LBLREVUSER',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
        ],
        data: [],
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.psi.lbe.protect.get.url;
      this.insertUrl = transactionConfig.psi.lbe.protect.insert.url;
      this.updateUrl = transactionConfig.psi.lbe.protect.update.url;
      this.deleteUrl = transactionConfig.psi.lbe.protect.delete.url;
      this.itemlistUrl = selectConfig.psi.lbe.protect.item.list.url;
      this.itemsaveUrl = transactionConfig.psi.lbe.protect.item.save.url;
      this.itemdeleteUrl = transactionConfig.psi.lbe.protect.item.delete.url;
      this.revlistUrl = selectConfig.psi.lbe.protect.list.url + '/revs';
      this.getData();
    },
    getData() {
      this.isRevision = false;
      if (this.popupParam.psiProtectionEpuipmentId) {
        this.getDetail(this.popupParam.psiProtectionEpuipmentId, true);
        this.selectedpsiProtectionEpuipmentId = this.popupParam.psiProtectionEpuipmentId
      }
    },
    getDetail(_psiProtectionEpuipmentId, _check) {
      this.$http.url = this.$format(this.detailUrl, _psiProtectionEpuipmentId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        // 상세팝업 최초작성/최근수정 정보 노출
        this.$emit('setRegInfo', _result.data)
        this.getList(_result.data.psiProtectionEpuipmentId);
        this.isRevision = false;
        if (_check) {
          this.getRevList(_result.data.groupId);
        }
        if(_result.data.psiProtectionEpuipmentId == this.popupParam.psiProtectionEpuipmentId) {
          this.editable = this.$route.meta.editable;
        } else {
          this.editable = false;
        }
      },);
    },
    getList(_psiProtectionEpuipmentId) {
      this.$http.url = this.$format(this.itemlistUrl, _psiProtectionEpuipmentId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    saveData() {
      if (this.popupParam.psiProtectionEpuipmentId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      let saveMsg = 'MSGSAVE';  // 저장하시겠습니까?
      if (this.isRevision) {
        saveMsg = 'MSGREVSAVE';
        // 현재버전을 개정하여 저장하시겠습니까?
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM', // 확인
            message: saveMsg,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              // 개정시
              if (this.isRevision) {
                this.saveUrl = this.insertUrl;
                this.mappingType = 'POST';
                this.data.sysRevision = parseInt(this.data.sysRevision) + 1;
              }
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM', // 확인
        message: 'MSG0010062',  // 세부품목도 모두 삭제됩니다.삭제하시겠습니까?
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.psiProtectionEpuipmentId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isdelete = !this.isdelete;
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.psiProtectionEpuipmentId = result.data.psiProtectionEpuipmentId
      this.saveCallData = uid();
      this.saveItem();
    },
    saveItem() {
      let checkItem = ['detailItemName']
      let isConti = true;
      this.$_.forEach(this.grid.data, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      });
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSG0010063',  // 필수 입력값을 입력해 주세요. [세부품목]
          type: 'warning', // success / info / warning / error
        });
      } else {
        let saveData = [];
        if (this.isRevision) {
          saveData = this.grid.data;
          this.$_.forEach(saveData, item => {
            item.editFlag = 'C';
            item.regUserId = this.$store.getters.user.userId;
            item.chgUserId = this.$store.getters.user.userId;
            item.psiProtectionEpuipmentId = this.popupParam.psiProtectionEpuipmentId;
          });
        } else {
          saveData = this.grid.data.filter( x => {
            return x.editFlag == 'C' || x.editFlag == 'U'
          });
          this.$_.forEach(saveData, item => {
            item.regUserId = this.$store.getters.user.userId;
            item.chgUserId = this.$store.getters.user.userId;
            item.psiProtectionEpuipmentId = this.popupParam.psiProtectionEpuipmentId;
          });
        }
        if(saveData.length > 0) {
          this.$http.url = this.itemsaveUrl;
          this.$http.type = 'POST';
          this.$http.param = saveData;
          this.$http.request(() => {
            window.getApp.$emit('ALERT', {
              title: 'LBLGUIDE ', /* 안내 */
              message: '저장되었습니다.', /* 저장되었습니다. */
              type: 'success', // success / info / warning / error
            });
            this.getData();
          });
        } else {
          this.getData();
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE ', /* 안내 */
            message: '저장되었습니다.', /* 저장되었습니다. */
            type: 'success', // success / info / warning / error
          });
        }
      }
    },
    addrow() {
      this.grid.data.push({
        editFlag: 'C',
        psiProtectionEpuipmentId: this.popupParam.psiProtectionEpuipmentId,  // 안전보호장구_비상방재물품_일련번호
        psiProtectionEpuipmentDetailId: '',  // 안전보호장구_비상방재물품_세부품목_일련번호
        detailItemName: '',  // 세부품목
        performanceStandard: '',  // 성능 및 규격
        equipAmount: '',  // 비치수량
      })
    },
    deleteItem() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.itemdeleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              this.getList();
              window.getApp.$emit('ALERT', {
                title: 'LBLGUIDE ', /* 안내 */
                message: '삭제되었습니다.', /* 삭제되었습니다. */
                type: 'success', // success / info / warning / error
              });
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    SetRevision() {
      this.$http.url = selectConfig.com.revision.getversion;
      this.$http.param = {revisionNum: this.data.revisionNum}
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data.revisionNum = _result.data;
        this.data.revisionContent = '';
        this.data.regDtStr = '';
        this.data.regUserName = '';
        this.data.sopMocId = '';
        this.isRevision = true;
      });
    },
    CancelRevision() {
      this.isRevision = false;
      this.getData();
    },
    getRevList(_groupId) {
      this.$http.url = this.revlistUrl;
      this.$http.type = 'GET';
      this.$http.param = {groupId: _groupId};
      this.$http.request((_result) => {
        this.gridrev.data = _result.data;
      },);
    },
    rowClickRev(row) {
      if (row) {
        this.selectedpsiProtectionEpuipmentId = row;
        if (this.selectedpsiProtectionEpuipmentId != this.data.psiProtectionEpuipmentId) {
          this.getDetail(this.selectedpsiProtectionEpuipmentId, false);
        }
      }
    },
  }
};
</script>
